<template>
  <div class="dialog-wrapper" @touchmove.prevent>
    <div class="dialog-box">
      <div class="dialog">
        <div class="title">{{home.bindRecommender}}</div>
        <div class="address">
          <input type="text" :placeholder="home.addressTips"
          class="input"
          v-model="parentAddress"
          :disabled="address ? true : false"
           />
        </div>
        <div class="tips text">*{{home.tipDialog}}METABILL</div>
        <div class="tips">LP{{home.balance}}：{{lpBalance}} LP</div>
        <div class="confirm">
          <van-button class="btn" @click="approveLP" :loading="approveLoading" v-if="!authLp">{{home.supplyLP}}</van-button>
          <van-button class="btn" @click="handleToSubmit" :loading="createLoading" v-else>{{home.confirm}}</van-button>
        </div>
      </div>
    </div>
    <img src="@/assets/close.png" class="delete-icon" @click="handleChange"/>
  </div>
</template>

<script>
export default {
  name: 'CreateAccountDialog',
  props: ['createLoading', 'authLp', 'approveLoading', 'toBindAddress', 'lpBalance'],
  data () {
    return {
      parentAddress: '',
      address: ''
    }
  },
  computed: {
    home () {
      return this.$t('home')
    },
    common () {
      return this.$t('common')
    }
  },
  methods: {
    getHasAddress () {
      this.address = this.$route.query.address || ''
      this.parentAddress = this.$route.query.address || ''
    },
    handleToSubmit () {
      if (!this.parentAddress) {
        return this.$toast(this.common.enterLink)
      }
      this.$emit('register', this.parentAddress)
    },
    handleChange () {
      this.$emit('handleToCreateAccount')
    },
    approveLP () {
      this.$parent.approveLP()
    }
  },
  mounted () {
    this.getHasAddress()
  }
}
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  max-width: 450px;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
  text-align: center;
  .dialog-box {
    padding: 0 48px;
    .dialog {
      text-align: left;
      text-align: center;
      background: #ffffff;
      border-radius: 15px;
      margin: 224px auto 0;
      box-sizing: border-box;
      padding: 24px 0;
      color: #000000;
      .title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      .address {
        padding: 0 18px;
        margin: 34px 0 10px;
        .input {
          height: 40px;
          background: #F3F3F3;
          border-radius: 5px;
          width: 100%;
          border: none;
          box-sizing: border-box;
          padding: 0 12px;
          font-size: 11px;
          &::placeholder {
            color: #878787;
            // color: rgba(0, 0, 0, 0.3);
          }
        }
      }
      .tips {
        text-align: left;
        padding: 0 18px;
        font-size: 12px;
        line-height: 24px;
        &.text {
          color: red;
        }
      }
      .tips:nth-child(2n){
        padding-left: 24px;
      }
      .confirm {
        margin-top: 10px;
        padding: 0 15px;
        .btn {
          width: 100%;
          height: 43px;
          line-height: 43px;
          background: linear-gradient(90deg, #622AFF 0%, #5E12E7 99%);
          border-radius: 22px;
          font-size: 13px;
          font-weight: 500;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }
  .delete-icon {
    cursor: pointer;
    margin: 30px auto 0;
    width: 30px;
    height: 30px;
  }
}
</style>

<template>
  <div class="container">
    <div class="box">
      <div class="content">
        <div class="title">{{ invite.myLink }}</div>
        <div class="link">{{ myInviteAddress }}</div>
        <div class="copy" @click="handleCopy(myInviteAddress)">{{ invite.copy }}</div>
      </div>
      <div class="content">
        <div class="list">
          <div class="invite">
            <div class="header">{{invite.inviteFriends}}</div>
            <div class="number">{{inviteFriends}}</div>
          </div>
          <!-- <div class="invite">
            <div class="header">{{invite.contributionValue}}</div>
            <div class="number">{{contributeCounts}}</div>
          </div> -->
        </div>
      </div>
      <div class="content grade-list">
        <div class="head">{{ invite.information }}</div>
        <div class="grade">
          <div class="grade-item title">
            <div class="left">{{invite.identityLevel}}</div>
            <div class="right">{{invite.inviteNumber}}</div>
          </div>
          <div class="grade-item">
            <div class="left">V0</div>
            <div class="right">{{gradeZero}}</div>
          </div>
          <div class="grade-item">
            <div class="left">V1</div>
            <div class="right">{{gradeOne}}</div>
          </div>
          <div class="grade-item">
            <div class="left">V2</div>
            <div class="right">{{gradeTwo}}</div>
          </div>
          <div class="grade-item">
            <div class="left">V3</div>
            <div class="right">{{gradeThree}}</div>
          </div>
        </div>
      </div>
      <div class="content grade-list">
        <div class="head">{{ invite.userList }}</div>
        <div class="grade">
          <div class="grade-item title">
            <div class="left">{{invite.userAddress}}</div>
            <div class="right">{{invite.identityLevel}}</div>
          </div>
          <div class="grade-item grade-box">
            <div class="box-item">
              <div class="left" v-for="(item, index) in addressList" :key="index">{{ item.slice(0, 4) }}...{{ item.slice(-4) }}</div>
            </div>
            <div class="box-item">
              <div class="left" v-for="(item, index) in vipList" :key="index">V{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateAccountDialog v-if="showCreateAccountDialog" @register="handleRegister" @handleToCreateAccount="handleToCreateAccount" :createLoading="createLoading" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import lighting from '@/contracts/lighting.js'
import CreateAccountDialog from '@/components/CreateAccountDialog.vue'
export default {
  name: 'Invite',
  components: { CreateAccountDialog },
  data () {
    return {
      myInviteAddress: 'http://uqtpnquhg.fo/rpj',
      tempAddress: [],
      showCreateAccountDialog: false,
      parentAddress: '',
      contributeCounts: 0,
      inviteFriends: '', // 邀请好友数量
      gradeZero: 0,
      gradeOne: 0,
      gradeTwo: 0,
      gradeThree: 0,
      createLoading: false,
      dividendAddress: [],
      addressList: [],
      vipList: []
    }
  },
  methods: {
    async init () {
      // this.getContributeCounts()
      await this.getAccounts()
      if (this.parentAddress) {
        this.myInviteAddress = window.location.host + '/#/home?address=' + this.myAcount
        this.followList()
      } else {
        this.handleToCreateAccount()
      }
    },
    // 获取账户
    async getAccounts () {
      const resp = await lighting.accounts()
      if (resp.success) {
        this.parentAddress = resp.result.head === '0x0000000000000000000000000000000000000000' ? '' : resp.result.head
      }
    },
    // 创建账户弹窗
    handleToCreateAccount () {
      this.showCreateAccountDialog = !this.showCreateAccountDialog
    },
    // 创建账号
    async handleRegister (parentAddress) {
      this.createLoading = true
      const resp = await lighting.register(parentAddress)
      this.createLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.$toast('success')
      this.showCreateAccountDialog = false
      this.init()
    },
    // 复制
    handleCopy (content) {
      const text = document.createElement('textarea')
      text.value = content
      document.body.appendChild(text)
      text.select() // 选择对象
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$toast('copied')
      }
      // 执行浏览器复制命令
      document.body.removeChild(text)
    },
    // 获取贡献值
    async getContributeCounts () {
      const resp = await lighting.contributeCounts(this.myAcount)
      if (resp.success) {
        this.contributeCounts = resp.result
      }
    },
    // 获取查询用户直推数据
    async followList () {
      const resp = await lighting.followList(this.myAcount)
      if (resp.success) {
        this.addressList = resp.result.addrs
        this.vipList = resp.result.vips
        console.log(resp, 'resp')
        this.inviteFriends = resp.result.addrs.length
        resp.result.vips.forEach(element => {
          switch (parseInt(element)) {
            case 0:
              this.gradeZero++
              break
            case 1:
              this.gradeOne++
              break
            case 2:
              this.gradeTwo++
              break
            default:
              this.gradeThree++
          }
        })
      }
    }
  },
  computed: {
    invite () {
      return this.$t('invite')
    },
    ...mapState(['myAcount'])
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.box {
  padding: 24px;
}
.container {
  // background: #242424;
  background: linear-gradient(0deg, #A439CF 0%, #F44F96 50%, #FF776F 100%);
  min-height: 100vh;

  .content {
    color: #fff;
    padding: 24px;
    margin-bottom: 24px;
    font-size: 14px;
    background: #171917;
    border-radius: 24px;

    .title {
      height: 24px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }

    .link {
      color: #FEFEFE;
      line-height: 20px;
      margin-bottom: 24px;
      word-break: break-all;
    }

    .copy {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: linear-gradient(90deg, #622AFF 0%, #5E12E7 99%);
      border-radius: 20px;
      cursor: pointer;
    }
    .list {
      display: flex;
      justify-content: space-around;
      .invite {
        text-align: center;
        .header {
          height: 18px;
          line-height: 18px;
          margin-bottom: 6px;
          font-size: 14px;
          color: #FEFEFE;
        }
        .number {
          height: 20px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .grade {
      color: #FFFFFF;
      background: #171917;
      border-radius: 25px;
      .grade-item {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 17px;
        .left {
          width: 50%;
          text-align: center;
        }
        .right {
          word-break: break-all;
          width: 50%;
          text-align: center;
        }
      }
      .grade-item:last-child {
        margin-bottom: 0;
      }
    }
  }
  .grade-list {
    padding: 24px 10px;
    .head {
      padding: 0 14px 15px;
      font-size: 16px;
    }
    .grade-box {
      display: flex;
      .box-item {
        width: 50%;
        text-align: center;
        .left {
          width: 100%;
          margin-bottom: 17px;
        }
        .left:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .grade-list:last-child {
    margin-bottom: 0;
  }
}
</style>
